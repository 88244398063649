<template>
	<div>
		<ContentHeader title="Data Karyawan" url="/data-karyawan" subTitle="Tambah Data Karyawan" />
		<div class="content-header">
			<div class="content-fluid">
				<div class="row justify-content-center">
					<div class="col">
						<div class="card">
							<div class="card-header row">
								<h3 class="card-title">Tambah Data Karyawan</h3>
							</div>
							<div class="card-body row justify-items-center">
								<div class="col-12">
		            				<div class="bs-stepper">
									  <div class="bs-stepper-header" role="tablist">
									    <!-- your steps here -->
									    <div class="step" v-bind:class="{ 'active' : stepper_toggle.satu }">
									      <button type="button" class="step-trigger" role="tab" aria-controls="personal-part" id="personal-part-trigger">
									        <span class="bs-stepper-circle">1</span>
									        <span class="bs-stepper-label">Personal</span>
									      </button>
									    </div>
									    <div class="line"></div>
									    <div class="step" v-bind:class="{ 'active' : stepper_toggle.dua }">
									      <button type="button" class="step-trigger" role="tab" aria-controls="keluarga-part" id="keluarga-part-trigger">
									        <span class="bs-stepper-circle">2</span>
									        <span class="bs-stepper-label">Keluarga</span>
									      </button>
									    </div>
									    <div class="line"></div>
									    <div class="step" v-bind:class="{ 'active' : stepper_toggle.tiga }">
									      <button type="button" class="step-trigger" role="tab" aria-controls="pendidikan-part" id="pendidikan-part-trigger">
									        <span class="bs-stepper-circle">3</span>
									        <span class="bs-stepper-label">Pendidikan</span>
									      </button>
									    </div>
									    <div class="line"></div>
									    <div class="step" v-bind:class="{ 'active' : stepper_toggle.empat }">
									      <button type="button" class="step-trigger" role="tab" aria-controls="pekerjaan-part" id="pekerjaan-part-trigger">
									        <span class="bs-stepper-circle">4</span>
									        <span class="bs-stepper-label">Pekerjaan</span>
									      </button>
									    </div>
									    <div class="line"></div>
									    <div class="step" v-bind:class="{ 'active' : stepper_toggle.lima }">
									      <button type="button" class="step-trigger" role="tab" aria-controls="info-tambahan-part" id="info-tambahan-part-trigger">
									        <span class="bs-stepper-circle">5</span>
									        <span class="bs-stepper-label">Info Tambahan</span>
									      </button>
									    </div>
									  </div>
									</div>
		            			</div>
								<div class="col-0 col-lg-2"></div>
								<div class="col-12 col-lg-8 my-3">
									<!-- PERSONAL -->
									<div id="personal" class="mt-5" v-if="toggle.personal">
										<form @submit.prevent="changePageTo(2)">
											<div id="data_pribadi">
												<h3>Data Pribadi</h3>
												<div class="form-group">
													<label >Nama Lengkap <span>*</span></label>
								                    <input type="text" class="form-control" autocomplete="off" required="" v-model="karyawan.nama_lengkap">	
												</div>	
												<div class="form-group">
													<label >Nama Panggilan <span>*</span></label>
								                    <input type="text" class="form-control" autocomplete="off" required="" v-model="karyawan.nama_panggilan">	
												</div>	
												<div class="form-group">
													<label >Nomor Handphone <span>*</span></label>
								                    <input type="tel" class="form-control" autocomplete="off" required="" v-model="karyawan.nomor_handphone">	
												</div>	
												<div class="form-group">
													<label >Email <span>*</span></label>
								                    <input type="email" class="form-control" autocomplete="off" required="" v-model="karyawan.email">	
												</div>	
												<div class="form-group">
													<label >Tempat Lahir <span>*</span></label>
								                    <input type="text" class="form-control" autocomplete="off" required="" v-model="karyawan.tempat_lahir">	
												</div>
												<div class="form-group">
													<label >Tanggal Lahir <span>*</span></label>
								                    <input type="date" class="form-control" autocomplete="off" required="" v-model="karyawan.tanggal_lahir">	
												</div>	
												<div class="form-group">
													<label >Jenis Kelamin <span>*</span></label>
								                    <select class="custom-select form-control" required="" v-model="karyawan.jenis_kelamin">
								                    	<option selected="" disabled="">- Pilih Jenis Kelamin -</option>
								                    	<option value="P">Perempuan</option>
								                    	<option value="L">Laki-laki</option>
								                    </select>
												</div>	
												<div class="form-group">
													<label >Status <span>*</span></label>
								                    <select class="custom-select form-control" required="" v-model="karyawan.status">
								                    	<option selected="" disabled="">- Pilih Status -</option>
								                    	<option value="single">Single</option>
								                    	<option value="sudah menikah">Sudah Menikah</option>
								                    	<option value="cerai mati">Cerai Mati</option>
								                    	<option value="cerai hidup">Cerai Hidup</option>
								                    </select>	
												</div>	
												<div class="form-group">
													<label >Agama <span>*</span></label>
								                    <select class="custom-select form-control" required="" v-model="karyawan.agama">
								                    	<option selected="" disabled="">- Pilih Agama -</option>
								                    	<option value="Islam">Islam</option>
				      									<option value="Kristen Protestan">Kristen Protestan</option>
				      									<option value="Kristen Katolik">Kristen Katolik</option>
				      									<option value="Hindu">Hindu</option>
				      									<option value="Budda">Budda</option>
				      									<option value="Konghucu">Konghucu</option>
								                    </select>	
												</div>	
												<div class="form-group">
													<label >Password</label>
								                    <input type="text" class="form-control" autocomplete="off" v-model="karyawan.password">	
												</div>
											</div>

											<div id="identitas_alamat" class="mt-5">
												<h3>Identitas & Alamat</h3>
												<div class="form-group">
													<label >Jenis Identitas <span>*</span></label>
								                    <select class="custom-select form-control" required="" v-model="karyawan.jenis_identitas">
								                    	<option selected="" disabled="">- Pilih Jenis Identitas -</option>
								                    	<option value="ktp">KTP</option>
								                    	<option value="sim">SIM</option>
								                    	<option value="passport">Passport</option>
								                    </select>	
												</div>
												<div class="form-group">
													<label >Nomor Identitas <span>*</span></label>
								                    <input type="number" class="form-control" autocomplete="off" required="" v-model="karyawan.nomor_identitas">	
												</div>
												<div class="form-group">
													<label >Alamat KTP <span>*</span></label>
								                    <textarea style="min-height: 100px;" class="form-control" autocomplete="off" required="" v-model="karyawan.alamat_ktp"></textarea>	
												</div>
												<div class="form-group">
													<label >Alamat Sekarang <span>*</span></label>
								                    <textarea style="min-height: 100px;" class="form-control" autocomplete="off" required="" v-model="karyawan.alamat_sekarang"></textarea>	
												</div>
												<div class="form-group">
													<label >Kode Pos</label>
								                    <input type="number" class="form-control" autocomplete="off" v-model="karyawan.kode_pos">	
												</div>
											</div>

											<div id="data_fisik_kesehatan" class="mt-5">
												<h3>Data Fisik & Kesehatan</h3>
												<div class="form-group">
													<label >Golongan Darah</label>
								                    <select class="custom-select form-control" v-model="karyawan.golongan_darah">
								                    	<option selected="" disabled="">- Pilih Golongan Darah -</option>
								                    	<option value="A">A</option>
								                    	<option value="B">B</option>
								                    	<option value="AB">AB</option>
								                    	<option value="O">O</option>
								                    </select>
												</div>
												<div class="row">
													<div class="col">
														<div class="form-group">
															<label >Tinggi Badan</label>
															<div class="input-group mb-3">
										                      <input type="number" class="form-control" autocomplete="off" v-model="karyawan.tinggi_badan">
											                  <!-- /btn-group -->
											                  <div class="input-group-prepend">
											                    <div type="button" class="btn btn-secondary">Cm</div>
											                  </div>
											                </div>	
														</div>
													</div>
													<div class="col">
														<div class="form-group">
															<label >Berat Badan</label>
															<div class="input-group mb-3">
										                      <input type="number" class="form-control" autocomplete="off" v-model="karyawan.berat_badan">
											                  <!-- /btn-group -->
											                  <div class="input-group-prepend">
											                    <div type="button" class="btn btn-secondary">Kg</div>
											                  </div>
											                </div>	
														</div>
													</div>
												</div>
												<div class="form-group">
													<label>Riwayat Penyakit <span>*</span></label>
													<textarea style="min-height: 100px;" class="form-control" required="" v-model="karyawan.riwayat_penyakit"></textarea>
												</div>
												<div class="form-group">
													<label for="">Foto Wajah</label>
													<div class="d-flex">
														<div>
															<input ref="foto_dom" type="file" class="mt-n2" @change="inputImage">
														</div>
													</div>
													<div class="d-flex align-items-center justify-content-between">
														<div>
															<img v-if="foto != null" :src="foto" style="width: auto;max-height: 400px;">
														</div>
													</div>
												</div>
												<div class="d-flex align-items-center justify-content-end mt-4">
													<router-link class="text-secondary" to="/data-karyawan">Batal</router-link>
													<button type="submit" class="btn btn-primary d-flex align-items-center ml-3">
													  <div>
													  	Lanjut
													  </div>	
													  <div>
									                    <svg v-if="loading" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style=" background: none; display: block; shape-rendering: auto;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
									                      <g>
									                        <path d="M50 15A35 35 0 1 0 74.74873734152916 25.251262658470843" fill="none" stroke="#ffffff" stroke-width="12"></path>
									                        <path d="M49 3L49 27L61 15L49 3" fill="#ffffff"></path>
									                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
									                      </g>

									                    </svg>
									                  </div>
													</button>
												</div>
											</div>
										</form>
									</div>
									<!-- END PERSONAL -->

									<!-- KELUARGA -->
									<div id="keluarga" class="mt-5" v-if="toggle.keluarga">
										<div id="data_keluarga">
											<h3>Data Keluarga (Kontak Darurat)</h3>
											<form>
								        		<div class="form-group">
													<label >Nama Lengkap </label>
								                    <input type="text" class="form-control" autocomplete="off" v-model="karyawan.nama_lengkap_keluarga">	
												</div>	
												<div class="form-group">
													<label >Hubungan <span>*</span></label>
								                    <select class="custom-select form-control" required="" v-model="karyawan.hubungan_keluarga">
								                    	<option selected="" disabled="">- Pilih Hubungan Keluarga -</option>
								                    	<option value="ayah">Ayah</option>
								                    	<option value="ibu">Ibu</option>
								                    	<option value="kakak">Kakak</option>
								                    	<option value="adik">Adik</option>
								                    	<option value="istri">Istri</option>
								                    	<option value="suami">Suami</option>
								                    	<option value="anak">Anak</option>
								                    </select>
												</div>	
												<div class="form-group">
													<label >Nomor Handphone</label>
								                    <input type="tel" class="form-control" autocomplete="off"  v-model="karyawan.nomor_handphone_keluarga">	
												</div>	
												<div class="form-group">
													<label >Nomor Identitas</label>
								                    <input type="number" class="form-control" autocomplete="off" v-model="karyawan.nomor_identitas_keluarga">	
												</div>	
												<div class="form-group">
													<label >Jenis Kelamin <span>*</span></label>
								                    <select class="custom-select form-control" required="" v-model="karyawan.jenis_kelamin_keluarga">
								                    	<option disabled="" selected="">- Pilih Jenis Kelamin -</option>
								                    	<option value="P">Perempuan</option>
								                    	<option value="L">Laki-laki</option>
								                    </select>	
												</div>	
												<div class="form-group">
													<label >Tempat Lahir</label>
								                    <input type="text" class="form-control" v-model="karyawan.tempat_lahir_keluarga">	
												</div>	
												<div class="form-group">
													<label >Tanggal Lahir</label>
								                    <input type="date" class="form-control" v-model="karyawan.tanggal_lahir_keluarga">	
												</div>	
												<div class="form-group">
													<label >Alamat</label>
								                    <textarea class="form-control" v-model="karyawan.alamat_keluarga"></textarea>	
												</div>	
												<div class="form-group">
													<label >Agama</label>
								                    <select class="custom-select form-control" v-model="karyawan.agama_keluarga">
								                    	<option selected="" disabled="">- Pilih Agama -</option>
								                    	<option value="Islam">Islam</option>
				      									<option value="Kristen Protestan">Kristen Protestan</option>
				      									<option value="Kristen Katolik">Kristen Katolik</option>
				      									<option value="Hindu">Hindu</option>
				      									<option value="Budda">Budda</option>
				      									<option value="Konghucu">Konghucu</option>
								                    </select>	
												</div>	
												<div class="form-group">
													<label >Status</label>
								                    <select class="custom-select form-control" v-model="karyawan.status_keluarga">
								                    	<option selected="" disabled="">- Pilih Status -</option>
								                    	<option value="single">Single</option>
								                    	<option value="sudah menikah">Sudah Menikah</option>
								                    	<option value="cerai mati">Cerai Mati</option>
								                    	<option value="cerai hidup">Cerai Hidup</option>
								                    </select>	
												</div>
												<div class="form-group">
													<label >Pekerjaan</label>
								                    <input type="text" class="form-control" v-model="karyawan.pekerjaan_keluarga">	
												</div>
												<div class="d-flex justify-content-end mt-4">
												<p class="text-secondary mt-2" style="cursor: pointer;" @click.prevent="changePageTo(1)">Kembali</p>
												<button type="submit" @click.prevent="changePageTo(3)" class="btn btn-primary ml-3">Lanjut</button>
											</div>
								        	</form>
										</div>
									</div>
									<!-- END KELUARGA -->

									<!-- PENDIDIKAN -->
									<div id="pendidikan" class="mt-5" v-if="toggle.pendidikan">
										<form @submit.prevent="changePageTo(4)">
											<div id="pendidikan_formal">
												<h3>Pendidikan Formal (Terakhir)</h3>
												<div class="form-group">
													<label >Jenjang Pendidikan <span>*</span></label>
								                    <select class="custom-select form-control" required="" v-model="karyawan.jenjang_pendidikan">
								                    	<option selected="" disabled="">- Pilih Jenjang Pendidikan -</option>
								                    	<option value="SD">SD</option>
								                    	<option value="MI">MI</option>
								                    	<option value="SMP">SMP</option>
								                    	<option value="MTS">MTS</option>
								                    	<option value="SMA">SMA</option>
								                    	<option value="SMK">SMK</option>
								                    	<option value="MAN">MAN</option>
								                    	<option value="Ahli Madya 1 (D1)">Ahli Madya 1 (D1)</option>
								                    	<option value="Ahli Madya 2 (D2)">Ahli Madya 2 (D2)</option>
								                    	<option value="Ahli Madya 3 (D3)">Ahli Madya 3 (D3)</option>
								                    	<option value="Ahli Madya 4 (D4)">Ahli Madya 4 (D4)</option>
								                    	<option value="Gelar Sarja (S1)">Gelar Sarja (S1)</option>
								                    	<option value="Gelar Master (S2)">Gelar Master (S2)</option>
								                    	<option value="Gelar Doktor (S3)">Gelar Doktor (S3)</option>
								                    	<option value="Profesi">Profesi</option>
								                    </select>	
												</div>	
												<div class="form-group">
													<label >Lembaga <span>*</span></label>
								                    <input type="text" class="form-control" autocomplete="off" required="" v-model="karyawan.lembaga">
												</div>	
												<div class="form-group">
													<label >Jurusan <span>*</span></label>
								                    <input type="text" class="form-control" autocomplete="off" required="" v-model="karyawan.jurusan">
												</div>	
												<div class="form-group">
													<label >Nilai <span>*</span></label>
								                    <input type="number" class="form-control" autocomplete="off" step="any" required="" v-model="karyawan.nilai">
												</div>
												<div class="row">
													<div class="col">
														<div class="form-group">
															<label >Tahun Masuk <span>*</span></label>
										                    <input type="number" min="1900" max="2099" step="1" class="form-control" autocomplete="off" required="" v-model="karyawan.tahun_masuk">
														</div>	
													</div>
													<div class="col">
														<div class="form-group">
															<label >Tahun Keluar <span>*</span></label>
										                    <input type="number" class="form-control" min="1900" max="2099" step="1" autocomplete="off" required="" v-model="karyawan.tahun_keluar">
														</div>	
													</div>
												</div>
											</div>
											<div id="pendidikan_informal" class="mt-5">
												<h3>Pendidikan Informal</h3>
												<div class="form-group">
													<label >Nama Pendidikan/Pelatihan </label>
								                    <input type="text" class="form-control" autocomplete="off" v-model="karyawan.nama_pelatihan">
												</div>
												<div class="form-group">
													<label >Diselenggarakan oleh </label>
								                    <input type="text" class="form-control" autocomplete="off" v-model="karyawan.penyelenggara">
												</div>
												<div class="row">
													<div class="col">
														<div class="form-group">
															<label >Tanggal Mulai Pelatihan </label>
										                    <input type="date" class="form-control" autocomplete="off" v-model="karyawan.tanggal_mulai_pelatihan">
														</div>		
													</div>
													<div class="col">
														<div class="form-group">
															<label >Tanggal Akhir Pelatihan </label>
										                    <input type="date" class="form-control" autocomplete="off" v-model="karyawan.tanggal_akhir_pelatihan">
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col">
														<div class="form-group">
															<label >Waktu Pelatihan </label>
															<div class="input-group mb-3">
										                      <input type="number" class="form-control" min="1" autocomplete="off" v-model="karyawan.waktu_pelatihan">
											                  <!-- /btn-group -->
											                  <div class="input-group-prepend">
											                    <div type="button" class="btn btn-secondary">Hari</div>
											                  </div>
											                </div>
														</div>		
													</div>
													<div class="col">
														<div class="form-group">
															<label >Biaya </label>
										                    <input type="number" min="0" class="form-control" autocomplete="off" v-model="karyawan.biaya">
														</div>		
													</div>
												</div>
												<div class="row">
													<div class="col">
														<div class="form-group">
															<label >Apakah Dapat Sertifikat </label>
															<br>
															<div class="form-check form-check-inline">
															  <input class="form-check-input" type="radio" name="apakah_dapat_sertifikat" id="dapat_sertifikat" value="1" v-model="karyawan.apakah_ada_sertifikat">
															  <label class="form-check-label" for="dapat_sertifikat">Ya</label>
															</div>
															<div class="form-check form-check-inline">
															  <input class="form-check-input" type="radio" name="apakah_dapat_sertifikat" id="tidak_dapat_sertifikat" value="0" v-model="karyawan.apakah_ada_sertifikat">
															  <label class="form-check-label" for="tidak_dapat_sertifikat">Tidak</label>
															</div>
														</div>
													</div>
													<div class="col">
														<div class="form-group">
															<label >Masa Berlaku Sertifikat </label>
										                    <input type="date" class="form-control" autocomplete="off" v-model="karyawan.masa_berlaku_sertifikat">
														</div>
													</div>
												</div>
											</div>
											<div id="pengalaman_kerja" class="mt-5">
												<h3>Pengalaman Kerja</h3>
												<div class="form-group">
													<label >Nama Perusahaan </label>
								                    <input type="text" class="form-control" autocomplete="off" v-model="karyawan.nama_perusahaan">
												</div>
												<div class="form-group">
													<label >Posisi/Jabatan </label>
								                    <input type="text" class="form-control" autocomplete="off" v-model="karyawan.jabatan">
												</div>
												<div class="form-group">
													<label >Gaji </label>
								                    <input type="number" min="0" class="form-control" autocomplete="off" v-model="karyawan.gaji">
												</div>
												<div class="row">
													<div class="col">
														<div class="form-group">
															<label >Tahun Masuk </label>
										                    <input type="date" class="form-control" autocomplete="off" v-model="karyawan.tahun_masuk_kerja">
														</div>
													</div>
													<div class="col">
														<div class="form-group">
															<label >Tahun Keluar </label>
										                    <input type="date" class="form-control" autocomplete="off" v-model="karyawan.tahun_keluar_kerja">
														</div>
													</div>
												</div>
												<div class="form-group">
													<label >Alasan Berhenti </label>
								                    <textarea class="form-control" style="min-height: 100px;" v-model="karyawan.alasan_berhenti"></textarea>
												</div>
											</div>
											<div class="d-flex justify-content-end mt-4">
												<p class="text-secondary mt-2" style="cursor: pointer;" @click.prevent="changePageTo(2)">Kembali</p>
												<button type="submit" class="btn btn-primary ml-3">Lanjut</button>
											</div>
										</form>
									</div>
									<!-- END PENDIDIKAN -->

									<!-- PEKERJAAN -->
									<div id="pekerjaan" class="mt-4" v-if="toggle.pekerjaan">
										<div id="data_pekerjaan">
											<h3>Data Pekerjaan</h3>
											<form @submit.prevent="changePageTo(5)"></form>
											<div class="row">
												<div class="col">
													<div class="form-group">
														<label>Tanggal Bergabung <span class="text-danger">*</span></label>
														<input type="date" class="form-control" required="" v-model="karyawan.tanggal_bergabung">
													</div>
												</div>
												<div class="col">
													<div class="form-group">
														<label>Tanggal Habis Kontrak</label>
														<input type="date" class="form-control" v-model="karyawan.tanggal_habis_kontrak">
													</div>
												</div>
											</div>
											<div class="form-group">
												<label >Wilayah <span class="text-danger">*</span></label>
							                    <select class="custom-select form-control" required="" v-model="karyawan.wilayah">
							                    	<option selected="" disabled="">- Pilih Wilayah -</option>
							                    	<option value="pusat">Pusat</option>
							                    	<option value="cabang">Cabang</option>
							                    </select>
											</div>	
											<div class="form-group">
												<label >Cabang <span class="text-danger">*</span></label>
							                    <select class="custom-select form-control" required="" @change="generateKode(karyawan.cabang_id)" v-model="karyawan.cabang_id">
							                    	<option selected="" disabled="">- Pilih Cabang -</option>
							                    	<option v-for="row in wilayah" :key="row.id" :value="row.id">{{ row.nama }}</option>
							                    </select>
											</div>
											<div class="form-group">
												<label >Divisi</label>
							                    <select class="custom-select form-control" v-model="karyawan.divisi">
							                    	<option selected="" disabled="">- Pilih Divisi -</option>
							                    	<option value="operasional">Operasional</option>
							                    	<option value="keuangan bendahara dan produksi">Keuangan Bendahara & Produksi</option>
							                    	<option value="hrd">HRD</option>
							                    	<option value="sekre">Sekre</option>
							                    	<option value="marketing">Marketing</option>
							                    	<option value="it">IT</option>
							                    	<option value="dokter">Dokter</option>
							                    	<option value="admin">Admin</option>
							                    	<option value="perawat">Perawat</option>
							                    	<option value="farmasi">Farmasi</option>
							                    	<option value="kecantikan">Kecantikan</option>
							                    	<option value="apotek">Apotek</option>
							                    	<option value="umum">Umum</option>
							                    </select>
											</div>	
											<div class="form-group">
												<label >Jabatan</label>
							                    <select class="custom-select form-control" v-model="karyawan.jabatan_kerja">
							                    	<option selected="" disabled="">- Pilih Jabatan -</option>
							                    	<option value="operasional">Operasional</option>
							                    	<option value="keuangan bendahara dan produksi">Keuangan</option>
							                    	<option value="hrd">HRD</option>
							                    	<option value="sekre">Sekretariat</option>
							                    	<option value="marketing">Marketing</option>
							                    	<option value="it">IT</option>
							                    	<option value="riset">Riset</option>
							                    	<option value="dokter">Dokter</option>
							                    	<option value="administrasi">Administrasi</option>
							                    	<option value="perawat">Perawat</option>
							                    	<option value="asisten perawat">Asisten Perawat</option>
							                    	<option value="bidan">Bidan</option>
							                    	<option value="apoteker">Apoteker</option>
							                    	<option value="asisten apoteker">Asisten Apoteker</option>
							                    	<option value="administrasi farmasi">Administrasi Farmasi</option>
							                    	<option value="front office">Front Office</option>
							                    	<option value="beautician">Beautician</option>
							                    	<option value="customer service">Customer Service</option>
							                    	<option value="logistik">Logistik</option>
							                    	<option value="administrasi apotek">Administrasi Apotek</option>
							                    	<option value="humas">Humas</option>
							                    	<option value="umum">Umum</option>
							                    </select>
											</div>
											<div class="form-group">
												<label >Level Jabatan</label>
							                    <select class="custom-select form-control" v-model="karyawan.level_jabatan">
							                    	<option selected="" disabled="">- Pilih Level Jabatan -</option>
							                    	<option value="pimpinan">Pimpinan</option>
							                    	<option value="manager">Manager</option>
							                    	<option value="supervisor wilayah">Supervisor Wilayah</option>
							                    	<option value="supervisor klinik">Supervisor Klinik</option>
							                    	<option value="penanggung jawab">Penanggung Jawab</option>
							                    	<option value="staff">Staff</option>
							                    </select>
											</div>	
											<div class="form-group">
												<label >Status Karyawan</label>
							                    <select class="custom-select form-control" v-model="karyawan.status_karyawan">
							                    	<option selected="" disabled="">- Pilih Status Karyawan -</option>
							                    	<option value="tetap">Tetap</option>
							                    	<option value="kontrak">Kontrak</option>
							                    	<option value="pelatihan">Pelatihan</option>
							                    	<option value="freelance">Freelance</option>
							                    	<option value="magang">Magang</option>
							                    </select>
											</div>	
											<div class="form-group">
												<label>No Identitas Karyawan</label>
												<input type="text" disabled="" class="form-control" v-model="karyawan.nomor_identitas_karyawan">
											</div>			
											<div class="row">
												<div class="col">
													<div class="form-group">
														<label>Nomor STR</label>
														<input type="text" name="" class="form-control" v-model="karyawan.nomor_str">
													</div>
												</div>
												<div class="col">
													<div class="form-group">
														<label>Masa Berlaku STR</label>
														<input type="date" class="form-control" name="" v-model="karyawan.masa_berlaku_str">
													</div>
												</div>
											</div>
											<div class="row">
												<div class="col">
													<div class="form-group">
														<label>Nomor Serkom</label>
														<input type="text" name="" class="form-control" v-model="karyawan.nomor_serkom">
													</div>
												</div>
												<div class="col">
													<div class="form-group">
														<label>Masa Berlaku Serkom</label>
														<input type="date" class="form-control" name="" v-model="karyawan.masa_berlaku_serkom">
													</div>
												</div>
											</div>
											<div class="row">
												<div class="col">
													<div class="form-group">
														<label>Nomor SIP</label>
														<input type="text" name="" class="form-control" v-model="karyawan.nomor_sip">
													</div>
												</div>
												<div class="col">
													<div class="form-group">
														<label>Masa Berlaku SIP</label>
														<input type="date" class="form-control" name="" v-model="karyawan.masa_berlaku_sip">
													</div>
												</div>
											</div>
										</div>
										<div id="data_asuransi" class="mt-4">
											<h3>Data Asuransi</h3>
											<div class="row">
												<div class="col">
													<div class="form-group">
														<label>Nama Asuransi</label>
														<select class="custom-select form-control" v-model="karyawan.nama_asuransi">
															<option selected="" disabled="">- Pilih Nama Asuransi -</option>
															<option value="BPJS Kesehatan">BPJS Kesehatan</option>
															<option value="BPJS Ketenagakerjaan">BPJS Ketenagakerjaan</option>
														</select>
													</div>
												</div>
												<div class="col">
													<div class="form-group">
														<label>Nomor Asuransi</label>
														<input type="number" class="form-control" v-model="karyawan.nomor_asuransi">
													</div>
												</div>
											</div>
											<div class="row">
												<div class="col">
													<div class="form-group">
														<label>Jenis Asuransi</label>
														<select class="custom-select form-control" v-model="karyawan.jenis_asuransi">
															<option selected="" disabled="">- Pilih Jenis Asuransi -</option>
															<option value="mandiri">Mandiri</option>
															<option value="perusahaan">Perusahaan</option>
														</select>
													</div>
												</div>
												<div class="col">
													<div class="form-group">
														<label>Iuran Asuransi</label>
														<input type="number" name="" class="form-control" v-model="karyawan.iuran_asuransi">
													</div>
												</div>
											</div>
											<div class="form-group">
												<label>FKTP (khusus bpjskes)</label>
												<input type="text" name="" class="form-control" v-model="karyawan.fktp">
											</div>
										</div>
										<div id="data_rekening_bank">
											<h3>Data Rekening Bank</h3>
											<div class="form-group">
												<label>Nama Bank</label>
												<select class="custom-select form-control" v-model="karyawan.nama_bank">
													<option selected="" disabled="">- Pilih Nama Bank</option>
													<option value="bca">BCA</option>
			      									<option value="bni">BNI</option>
			      									<option value="bri">BRI</option>
			      									<option value="mandiri">MANDIRI</option>
												</select>
											</div>
											<div class="form-group">
												<label>Nomor Rekening</label>
												<input type="number" name="" class="form-control" v-model="karyawan.nomor_rekening">
											</div>
											<div class="form-group">
												<label>Nama Pemilik Rekening</label>
												<input type="text" name="" class="form-control" v-model="karyawan.nama_pemilik_rekening">
											</div>
										</div>
										<div class="d-flex justify-content-end mt-4">
											<p class="text-secondary mt-2" style="cursor: pointer;" @click.prevent="changePageTo(3)">Kembali</p>
											<button type="submit" class="btn btn-primary ml-3" @click.prevent="changePageTo(5)">Lanjut</button>
										</div>
									</div>
									<!-- END PEKERJAAN -->

									<!-- INFO TAMBAHAN -->
									<div id="info_tambahan" class="mt-4" v-if="toggle.info_tambahan">
										<h3>Info Tambahan</h3>
										<form @submit.prevent="prosesDataKaryawan">
											<div class="form-group">
												<label>Ukuran Seragam</label>
												<select class="custom-select form-control" v-model="karyawan.ukuran_seragam">
													<option selected="" disabled="">- Pilih Ukuran Seragam -</option>
													<option value="s">S</option>
													<option value="m">M</option>
													<option value="l">L</option>
													<option value="xl">XL</option>
												</select>
											</div>
											<div class="d-flex justify-content-end mt-4">
												<p class="text-secondary mt-2" style="cursor: pointer;" @click.prevent="changePageTo(4)">Kembali</p>
												<button type="submit" class="btn btn-primary d-flex align-items-center ml-3">
												  <div>
												  	Simpan
												  </div>	
												  <div>
								                    <svg v-if="loading" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style=" background: none; display: block; shape-rendering: auto;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
								                      <g>
								                        <path d="M50 15A35 35 0 1 0 74.74873734152916 25.251262658470843" fill="none" stroke="#ffffff" stroke-width="12"></path>
								                        <path d="M49 3L49 27L61 15L49 3" fill="#ffffff"></path>
								                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
								                      </g>

								                    </svg>
								                  </div>
												</button>
											</div>
										</form>
									</div>
									<!-- END INFO TAMBAHAN -->
								</div>
								<div class="col-0 col-lg-2"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style>
	.stepper{
		border: 2px solid #444;
		border-radius: 50%;
	}

	.stepper_active{
		border: 2px solid #60A5FA;
		border-radius: 50%;
		color:  #60A5FA;	
	}
</style>

<script setup>
import ContentHeader from '@/components/ContentHeader2'

import { reactive, ref, onMounted, computed } from 'vue'
// import store from '@/store'
import Swal from 'sweetalert2'
import router from '@/router'
import axios from 'axios'
import { ElMessage } from 'element-plus'
import { objectToForm } from 'object-to-form'
import store from '@/store'

const cabangLogin = computed(() => store.getters['auth/cabang'])
const loading = ref(false)
const toggle = reactive({
	'personal' : true,
	'keluarga' : false,
	'pendidikan' : false,
	'pekerjaan' : false,
	'info_tambahan' : false,
})
const stepper_toggle = reactive({
	'satu' : true,
	'dua' : false,
	'tiga' : false,
	'empat' : false,
	'lima' : false
})
const { wilayah, karyawan, getCabang } = useData(cabangLogin)

const changePageTo = (id) => {
	loading.value = true
	
	toggle.personal = false 
	toggle.keluarga = false
	toggle.pendidikan = false
	toggle.pekerjaan = false
	toggle.info_tambahan = false

	if (id == 1) {
		loading.value = false
		toggle.personal = true
		stepper_toggle.dua = false
		stepper_toggle.tiga = false
		stepper_toggle.empat = false
		stepper_toggle.lima = false
	} else if (id == 2) {
		loading.value = false
		toggle.keluarga = true
		stepper_toggle.dua = true
		stepper_toggle.tiga = false
		stepper_toggle.empat = false
		stepper_toggle.lima = false
	} else if (id == 3) {
		loading.value = false
		toggle.pendidikan = true
		stepper_toggle.tiga = true
		stepper_toggle.empat = false
		stepper_toggle.lima = false
	} else if (id == 4) {
		loading.value = false
		toggle.pekerjaan = true
		stepper_toggle.empat = true
		stepper_toggle.lima = false
	} else if (id == 5) {
		loading.value = false
		toggle.info_tambahan = true
		stepper_toggle.lima = true
	}
}


const generateKode = (id) => {
	let tanggal = karyawan.tanggal_bergabung.slice(8, 10)
	let bulan = karyawan.tanggal_bergabung.slice(5, 7)
	let tahun = karyawan.tanggal_bergabung.slice(0, 4)

	let jenis_kelamin = ''
	if (karyawan.jenis_kelamin == 'L') {
		jenis_kelamin = 1	
	} else {
		jenis_kelamin = 2
	}
	let kode_wilayah_cabang
	let nama_cabang
	
	for (var i = 0; i < wilayah.value.length; i++) {
		if (wilayah.value[i].id == id) {
			kode_wilayah_cabang = wilayah.value[i].kode_wilayah
			nama_cabang = wilayah.value[i].nama
		}
	}

	karyawan.nomor_identitas_karyawan = `${tanggal}${bulan}${tahun}${jenis_kelamin}RSSG${kode_wilayah_cabang}`
	karyawan.cabang = nama_cabang
}

// Foto handle
const foto = ref(null)
const foto_dom = ref('')
const inputImage = (e) => {
	let files = e.target.files || e.dataTransfer.files
	if(files[0].type == 'image/png' || files[0].type == 'image/jpeg') {
		if (files[0].size > 2000000) {
			karyawan.foto = ''
			foto_dom.value.value = ''
			foto.value = null
			ElMessage({
				message: 'Ukuran file harus kurang dari 2MB.',
				type: 'warning',
			})
		} else {
			karyawan.foto = files[0]
			createImage(files[0]);
		}
	}  else {
		karyawan.foto = ''
		foto_dom.value.value = ''
		foto.value = null
		ElMessage({
			message: 'Format file harus png atau jpeg.',
			type: 'warning',
		})
	}
}

const createImage = (file) => {
	let reader = new FileReader();

	reader.onload = (e) => {
		foto.value = e.target.result;
	}

	if (file) reader.readAsDataURL(file) 
}
// End foto handle

const prosesDataKaryawan = () => {
	loading.value = true
	axios.post('api/karyawan/create', objectToForm(karyawan))
	.then((response) => {
		Swal.fire({
			title: 'Berhasil!',
			text: 'Berhasil Menanbahkan Data',
			icon: 'success',
			showConfirmButton: false,
			timer: 1500
		})	
		loading.value = false
		router.push('/data-karyawan')
		console.log(response)
	})
	.catch((error) => {
		Swal.fire({
			title: 'Gagal!',
			text: 'Gagal Menanbahkan Data',
			icon: 'error',
			confirmButtonText: 'Ok'
		})	
		loading.value = false
		console.log(error)
	})
}

onMounted(() => {
	getCabang()
})

function useData (cabangLogin) {

	const karyawan = reactive({
		'cabang_id' : '',
		'nama_lengkap' : '',
		'nama_panggilan' : '',
		'nomor_handphone' : '',
		'email' : '',
		'tempat_lahir' : '',
		'tanggal_lahir' : '',
		'jenis_kelamin' : '',
		'status' : '',
		'agama' : '',
		'password' : '',
		'jenis_identitas' : '',
		'nomor_identitas' : '',
		'alamat_ktp' : '',
		'alamat_sekarang' : '',
		'kode_pos' : '',
		'golongan_darah' : '',
		'tinggi_badan' : '',
		'berat_badan' : '',
		'riwayat_penyakit' : '',
		// Keluarga
		'nama_lengkap_keluarga' : '',
		'hubungan_keluarga' : '',
		'nomor_handphone_keluarga' : '',
		'nomor_identitas_keluarga' : '',
		'jenis_kelamin_keluarga' : '',
		'tempat_lahir_keluarga' : '',
		'tanggal_lahir_keluarga' : '',
		'alamat_keluarga' : '',
		'agama_keluarga' : '',
		'status_keluarga' : '',
		'pekerjaan_keluarga' : '',
		// Pendidikan formal
		'jenjang_pendidikan' : '',
		'lembaga' : '',
		'jurusan' : '',
		'nilai' : '',
		'tahun_masuk' : '',
		'tahun_keluar' : '',
		// pendidikan informal
		'nama_pelatihan' : '',
		'penyelenggara' : '',
		'tanggal_mulai_pelatihan' : '',
		'tanggal_akhir_pelatihan' : '',
		'waktu_pelatihan' : '',
		'biaya' : '',
		'apakah_ada_sertifikat' : '',
		'masa_berlaku_sertifikat' : '',
		// Pengalaman Kerja
		'nama_perusahaan' : '',
		'jabatan' : '',
		'gaji' : '',
		'tahun_masuk_kerja' : '',
		'tahun_keluar_kerja' : '',
		'alasan_berhenti' : '',
		// Pekerjaan
		'wilayah' : '',
		'cabang' : '',
		'divisi' : '',
		'jabatan_kerja' : '',
		'level_jabatan' : '',
		'status_karyawan' : '',
		'tanggal_bergabung' : '',
		'tanggal_habis_kontrak' : '',
		'nomor_identitas_karyawan' : '',
		'nomor_str' : '',
		'masa_berlaku_str' : '',
		'nomor_serkom' : '',
		'masa_berlaku_serkom' : '',
		'nomor_sip' : '',
		'masa_berlaku_sip' : '',
		// data asuransi
		'nama_asuransi' : '',
		'nomor_asuransi' : '',
		'jenis_asuransi' : '',
		'iuran_asuransi' : '',
		'fktp' : '',
		// data rekening bank
		'nama_bank' : '',
		'nomor_rekening' : '',
		'nama_pemilik_rekening' : '',
		// info tambahan
		'ukuran_seragam' : '',
		'foto' : ''
	})

	const wilayah = ref([])

	const getCabang = async () => {
		let { data } = await axios.get(`api/cabang/${cabangLogin.value.tipe}`)

		wilayah.value = data
	}


	return { karyawan, wilayah, getCabang }
}
</script>

<style type="text/css" src="@/assets/bs-stepper.min.css"></style>